
import { Vue, Options } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { Encounter, FormSection, FormSubmission } from '@/models';
import { BaseButton, ButtonLink, SmartFormComplete, SmartFormSection } from '@/lib/components';
import { countErrors, validate } from '@/helpers/validators.helper';
import { SavingStatus } from '@/lib/constants';
import { Laterality } from '@/custom/menicon/models';
import DistanceVA from '@/custom/menicon/views/encounter/DistanceVA.vue';
import { EncounterData } from '@/helpers/encounter.helper';
import EndPrescription from '@/custom/menicon/components/EndPrescription.vue';
import LensCollectionConfirmation from '@/custom/menicon/views/encounter/LensCollectionConfirmation.vue';
import SurveyResultCharts from '@/custom/menicon/SurveyResultCharts.vue';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
import SlitLampWithoutLenses from '@/custom/menicon/views/encounter/SlitLampWithoutLenses.vue';

@Options({
  components: {
    SurveyResultCharts,
    LensCollectionConfirmation,
    EndPrescription,
    DistanceVA,
    SmartFormSection,
    WorkflowLayout,
    BaseButton,
    SmartFormComplete,
    ButtonLink,
    SlitLampWithoutLenses
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    journeyId: {
      type: String,
      required: true
    },
    encounterId: {
      type: String,
      default: null
    },
    patient: {
      type: Object,
      required: true
    },
    endPrescriptionValue: {
      type: Object,
      default: () => ({
        reasons: [],
        details: ''
      })
    },
    slitLampConfig: {
      type: Object,
      default: null
    },
    journeyType: {
      type: String,
      required: true
    },
    currentPrescriptions: {
      type: Object,
      default: null
    },
    encounterData: {
      type: Object,
      default: null
    },
    encounter: {
      type: Object,
      default: null
    },
    formSubmission: {
      type: Object,
      default: null
    },
    initialEncounter: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: SavingStatus.UNSET
    },
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ''
    },
    laterality: {
      type: String,
      default: null
    },
    lateralities: {
      type: Array,
      default: () => [Laterality.right, Laterality.left]
    }
  },
  inheritAttrs: false
})
export default class EncounterPage extends Vue {
  laterality!: Laterality | null;
  errors!: { [key: string]: string[] };
  initialEncounter!: EncounterData;
  encounter!: Encounter;
  rules!: { [key: string]: any };
  endPrescription = false;

  formSubmission!: FormSubmission | null;

  slitLampWithContactLensesSectionId = '4121b65a-a256-464d-9929-e90c5d94d9c2';

  created() {
    this.$watch('encounter', () => {
      if (this.errorCount) {
        this.validateCurrentStep();
      }
    });
  }

  get showVirtualDoctor() {
    return isFeatureFlagEnabled(FEATURES.VIRTUAL_DOCTOR);
  }

  get smartFormCompleteProps() {
    return this.endPrescription
      ? {
        heading: this.$t('custom.menicon.encounter.prescription-ended-confirmation.heading') as string,
        body: this.$t('custom.menicon.encounter.prescription-ended-confirmation.body') as string
      }
      : {
        heading: this.$t('custom.menicon.encounter.bloom-day-consult-complete.heading') as string,
        body: this.$t('custom.menicon.encounter.bloom-day-consult-complete.body') as string
      };
  }

  get steps(): string[] {
    return [
      ...(this.showVirtualDoctor ? [this.$t('custom.menicon.virtual-doctor.name') as string] : []),
      this.$t('custom.menicon.encounter.va.name') as string,
      this.$t('custom.menicon.encounter.slit-lamp-with-lenses.name') as string,
      this.$t('custom.menicon.encounter.slit-lamp-without-lenses.name') as string,
      this.$t('custom.menicon.encounter.lens-confirmation.name') as string,
      ...(this.endPrescription ? [this.$t('custom.menicon.lens-ordering.cancel.title') as string] : [])
    ];
  }

  get step(): number {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get slitLampWithContactLensesSection(): FormSection | undefined {
    return this.formSubmission?.form?.schema?.sections.find(
      (section: FormSection) => section.id === this.slitLampWithContactLensesSectionId
    );
  }

  get currentSectionId(): string | null {
    if (this.step === this.getStep(this.$t('custom.menicon.encounter.slit-lamp-with-lenses.name') as string)) {
      return this.slitLampWithContactLensesSectionId;
    }
    return null;
  }

  get errorCount(): number {
    return countErrors(this.errors);
  }

  onEndPrescriptionClick() {
    this.endPrescription = true;
    this.next();
  }

  next() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('next', this.currentSectionId);
    }
  }

  complete() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('complete', { sectionId: this.currentSectionId });
    }
  }

  validateCurrentStep() {
    let errors = {};
    if (this.step === this.getStep(this.$t('custom.menicon.encounter.va.name') as string) && this.rules?.distanceVa) {
      errors = validate(this.rules.distanceVa, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.slit-lamp-without-lenses.name') as string) &&
      this.rules?.slitLamp
    ) {
      errors = validate(this.rules.slitLamp, this.encounter);
    }
    this.$emit('setErrors', errors);
  }

  back() {
    this.endPrescription = false;
    this.$emit('back');
  }

  getStep(name: string): number {
    return this.steps.findIndex((step) => step === name);
  }
}
